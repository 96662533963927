import React from 'react'
import Layout from '../components/layout'
import { Heading } from '../components/Typography'

export default ({ pageContext, location }) => {
  const { html, frontmatter } = pageContext

  return (
    <Layout {...{ location }}>
      <div className='container'>
        <div className='py-3'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='layout-sub-category'>
                <Heading>{frontmatter.title}</Heading>
                <div dangerouslySetInnerHTML={{ __html: html || '' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
